<template>
  <div>
    <v-row justify="space-around" class="mt-3 mb-3">
      <v-col md="6" sm="12">
        <v-card :loading="loading">
          <v-card-text>
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="item in snipletsGruppi" :key="item.codice">
            <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col cols="3">{{item.descrizione}}</v-col>
                <v-col cols="8" class="text--secondary">{{item.note}}</v-col>
                <v-col cols="1" v-if="!open" title="Selezionati / Esistenti (di cui Default)">{{totaleSelezionati(item)}}</v-col>        
              </v-row>
            </template>          
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row justify="start" no-gutters>
                <v-col>
                  <v-list flat>
                    <v-list-item-group v-model="settings" multiple active-class="">
                      <v-list-item v-for="snip in snipletsEsistentiGruppo(item.codice, triggerGruppo)" :key="snip.codice" dense :three-line="snip.note && snip.note.length > 60">
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-badge :value="snip.default" bordered color="success" icon="mdi-lock" overlap title="Impostazione di default">
                              <v-badge :value="snip.daPersonalizzare" color="error" class="nested-badge" bordered bottom dot left overlap title="Parametro da personalizzare">
                                <v-checkbox :input-value="active" v-model="snip.selected" @change="datiModificati = true"></v-checkbox>
                              </v-badge>                                
                            </v-badge>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{snip.personalizzato ? '* ' : ''}}{{snip.titolo}}</v-list-item-title>
                            <v-list-item-subtitle>{{snip.note}}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-avatar v-if="snip.daPersonalizzare && snip.selected">
                            <v-icon @click="onEditItem(snip)" title="Modifica i parametri">mdi-pencil</v-icon>
                            <!-- <v-icon @click="onEditCustomerItem(snip)" small title="Modifica i parametri salvati per il cliente">mdi-account-star-outline</v-icon> -->
                          </v-list-item-avatar>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
      <v-col md="6" sm="12" lg="5">
          <v-list flat three-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Caricamento contenuti di default</v-list-item-title>
                <v-list-item-subtitle>
                  Come prima operazione è consigliabile caricare le impostazioni di default per avere un sistema con le funzioni di base già caricate
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn :disabled="loading" @click.stop="onAggiungiDefault">Aggiungi default</v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Salvataggio configurazione</v-list-item-title>
                <v-list-item-subtitle>
                  Salva le impostazioni per il cliente ed aggiorna i relativi database
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn :disabled="loading || !datiModificati"  color="primary" @click.stop="onSalvaImpostazioni">Salva le configurazioni</v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
      </v-col>
    </v-row>  
      <dialog-conferma
        :show="dialogConferma"
        :title="titoloConferma"
        :message="messaggioConferma"
        :onlyOk="onlyOk"
        :confirmText="confirmText"
        @dialog-confirm="onConferma"
        @dialog-cancel="operazioneEseguitaConfirm"
      />

      <v-dialog v-model="dialogModifica" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Modifica avanzata parametri</span>
          </v-card-title>
          <v-card-text>
            <v-json-editor v-model="jsonWeb" :options="optionsWeb" :plus="true" history @error="onErrorWeb" style="height: 100%;" ref="jsonEditor"/>
            <small>Per ricaricare i valori di default dell'impostazione (se modificata per il cliente) è sufficiente rimuovere il paramentro e ricaricare la lista</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="onEditorChiudi">Chiudi</v-btn>
            <v-btn color="blue darken-1" text @click="onEditorSalva">Salva</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>    
</template>

<script>
  import _ from 'lodash'
  import VJsonEditor from 'v-jsoneditor'
  import sniplets from '@/services/snipletsService'
  import configurazioni from '@/services/configurazioniRemoteService'
  import DialogConferma from '@/components/dialogConferma'

  export default {
    components: {
      VJsonEditor,
      DialogConferma
    },
    props: {
      cliente: Object
    },
    data (vm) {
      return {
        dialogConferma: false,
        titoloConferma: '',
        messaggioConferma: '',
        onlyOk: false,
        onConferma: vm.operazioneEseguitaConfirm,
        confirmText: 'SI',
        baseDb: null,
        snipletsEsistenti: [],
        snipletsGruppi: [],
        snipletsCliente: [],
        settings: [],
        editedItem: null,
        dialogModifica: false,
        jsonWeb: '',
        jsonMode: 'code',        
        optionsWeb: {
          mode: 'form',
          modes: ['code', 'form'],
          search: true,
          enableSort: false,
          enableTransform: false
        },
        triggerGruppo: 1,
        loading: false,
        datiModificati: false
      }
    },
    async mounted() {
      let baseDb = null
      if (this.cliente && this.cliente.licenze) {
        const licenza = this.cliente.licenze[0]
        this.licenza = licenza
        baseDb = licenza.baseDb
      } else {
        baseDb = this.cliente.baseDb
      }
      this.baseDb = baseDb
      this.snipletsCliente = this.cliente.sniplets || []
      this.loading = true
      await this.leggeSnipletsEsistenti()
      await this.aggiornaSniplets()
      this.loading = false
      this.triggerGruppo++
    },
    computed: {
/*       gruppiSniplets() {
        return this.snipletsEsistenti.length > 0 ? _.sortedUniq(this.snipletsEsistenti.map(x => x.gruppo)) : []
      } */
    },
    methods: {
      onErrorWeb() {
        console.log('errore da gestire')
      },
      onEditItem(snip) {
        this.editedItem = snip
        this.jsonWeb = snip.payload
        // const jEd = this.$refs.jsonEditor
        // jEd.expandAll()
        this.dialogModifica = true
      },
      onEditorChiudi() {
        this.editedItem = null
        this.jsonWeb = ''
        this.dialogModifica = false
      },
      onEditorSalva() {
        if (!this.editedItem.payload || JSON.stringify(this.editedItem.payload) !== JSON.stringify(this.jsonWeb)) {
          this.editedItem.payload = this.jsonWeb
          this.editedItem.personalizzato = true
        } else {
          this.editedItem.personalizzato = false
        }
        this.editedItem.modified = true
        this.editedItem = null
        this.jsonWeb = ''
        this.dialogModifica = false
        this.datiModificati = true
      },
      async leggeSnipletsEsistenti() {
        const items = await sniplets.getSniplets()
        this.snipletsEsistenti = items.elenco
        this.snipletsGruppi = _.sortBy(items.gruppi, 'descrizione')
        this.triggerGruppo++
      },
      async aggiornaSniplets() {
        for (let sn of this.snipletsEsistenti) {
          if (!sn.daPersonalizzare) {
            sn.daPersonalizzare = false
          }
          if (this.snipletsCliente.find(x => x.codice === sn.codice)) {
            // se è personalizzabile ed è presente nel cliente, va a rileggere quello del cliente per eventuali modifiche
            sn.selected = true
            sn.selectedOriginal = true
            sn.personalizzato = false
            const sc = await this.leggeDatabaseCliente(sn)
            if (sc) {
              delete sc._rev
              delete sc.editInfo
              if (JSON.stringify(sn.payload) !== JSON.stringify(sc)) {
                sn.payload = sc
                sn.personalizzato = true
              }
            }
          } else {
            sn.selected = false
            sn.selectedOriginal = false
            sn.personalizzato = false
          }
        }
        this.$forceUpdate()
      },
      onAggiungiDefault() {
        this.titoloConferma = 'Impostazioni di default'
        this.messaggioConferma = `Confermi caricamento impostazioni di default ?`
        this.onConferma = this.aggiungiDefault
        this.dialogConferma = true
      },
      aggiungiDefault() {
        this.dialogConferma = false
        let esistentiDefault = this.snipletsEsistenti.filter(x => x.default)
        // for (let sn of this.snipletsEsistenti.filter(x => x.default)) {
        for (let sn of esistentiDefault) {
          if (!this.snipletsCliente.find(x => x.codice === sn.codice)) {
            this.snipletsCliente.push({
              codice: sn.codice
              // verificare altri parametri
            })
          }
          sn.selected = true
        }
        this.datiModificati = true
      },
      onSalvaImpostazioni() {
        this.titoloConferma = 'Salvataggio configurazione'
        this.messaggioConferma = `Confermi il salvataggio della configurazione e l'aggiornamento dei database ?`
        this.onConferma = this.salvaImpostazioni
        this.dialogConferma = true
      },
      async salvaImpostazioni() {
        this.dialogConferma = false
        const selezionati = this.snipletsEsistenti.filter(x => x.selected).map(x => {
          return {
            codice: x.codice
            // verificare altri parametri
          }
        })
        this.loading = true
        this.snipletsCliente = await sniplets.salvaConfigurazioneCliente(this.cliente.codice, selezionati)
        await this.aggiornaDatabaseCliente()
        await this.aggiornaSniplets()
        this.loading = false
        this.triggerGruppo++
        this.datiModificati = false
      },
 /*      async applicaSniplet(sn) {
         // posso dover inserire la stessa configurazioni su più posizioni (es. layout) --> separate da ;
         debugger
        const arrPosizioni = sn.posizione.split(';')
        for (const pps of arrPosizioni) {
          if (sn.categoria === 'Impostazioni') {
            debugger
            // configurazioni.gruppi_tasti.layout_04.gruppi.[zona: 'tasti2']
            const posizione = pps.split('.')
            const nomeDb = `${this.cliente.baseDb}_${posizione[0]}`
            if (!nomeDb) continue // attenzione undefined
            switch(posizione.length) {
              case 0:
                break;              
              case 1:
                await configurazioni.modificaConfigurazione(nomeDb, sn.payload)
                break;
              default:
                debugger
                const confId = posizione[1] || ''
                await configurazioni.modificaConfigurazioneSub(nomeDb, confId, sn.payload)
                break;
            }
          }
          if (sn.categoria === 'ValoriDB') {
            const nomeDb = `${this.cliente.baseDb}_${sn.posizione}`
            await configurazioni.modificaItemGenerico(nomeDb, sn.payload)
          }
        }
      },    */   
      async applicaSniplet(sn) {
        if (sn.categoria === 'Impostazioni') {
          const posizione = sn.posizione.split('.')
          const nomeDb = `${this.baseDb}_${posizione[0]}`
          let confId = ''
          switch(posizione.length) {
            case 1:
              await configurazioni.modificaConfigurazione(nomeDb, sn.payload)
              break;
            case 2:
              confId = posizione[1] || ''
              await configurazioni.modificaConfigurazioneSub(nomeDb, confId, sn.payload)
              break;
            case 3:
              confId = posizione[1] || ''
              const subConf = posizione[2] || ''
              await configurazioni.modificaConfigurazioneSubDettaglio(nomeDb, confId, subConf, sn.payload)
              break;  
            default:
              break;
          }
        }
        if (sn.categoria === 'ValoriDB') {
          const nomeDb = `${this.baseDb}_${sn.posizione}`
          await configurazioni.modificaItemGenerico(nomeDb, sn.payload)
        }
      },
      async aggiornaDatabaseCliente() {
        const selezionati = this.snipletsEsistenti.filter(x => x.selected)
        const daAggiungereDefault = selezionati.filter(x => (!x.selectedOriginal || x.modified) && x.default)
        const daAggiungere = selezionati.filter(x => (!x.selectedOriginal || x.modified) && !x.default)
        const daTogliere = this.snipletsEsistenti.filter(x => x.selectedOriginal && !x.selected)
        for (let sn of daAggiungereDefault) {
          await this.applicaSniplet(sn)
        }
        for (let sn of daAggiungere) {
          await this.applicaSniplet(sn)
        }
      },
      async leggeDatabaseCliente(sn) {
        var cc = this.cliente
        if (sn.categoria === 'Impostazioni') {
          const posizione = sn.posizione.split('.')
          const nomeDb = `${this.baseDb}_${posizione[0]}`
          if (!nomeDb || !['clienti', 'configurazioni','documenti', 'fidelity', 'magazzino', 'movimenti', 'planning', 'prodotti', 'scontrini'].includes(posizione[0])) return null
          switch(posizione.length) {
            case 1:
              return await configurazioni.leggeConfigurazione(nomeDb, sn.payload)
            case 2:
              const confId = posizione[1] || ''
              return await configurazioni.leggeConfigurazioneSub(nomeDb, confId, sn.payload)
            default:
              break;
          }
        }
        if (sn.categoria === 'ValoriDB') {
          const nomeDb = `${this.baseDb}_${sn.posizione}`
          return await configurazioni.getItemGenerico(nomeDb, sn.payload)
        }
      },
      totaleSelezionati(item) {
        const snGruppo = this.snipletsEsistenti.filter(x => x.gruppo === item.codice)
        const es = snGruppo.length
        const def = snGruppo.filter(x => x.default).length
        const sel = snGruppo.filter(x => x.selected).length
        return `${sel}/${es} (${def})`
      },
      snipletsEsistentiGruppo(gruppo) {
        return _.sortBy(this.snipletsEsistenti.filter(x => x.gruppo === gruppo), 'titolo')
      },
      operazioneEseguita(messaggio) {
        this.titoloConferma = 'Operazione eseguita'
        this.messaggioConferma = messaggio || 'Operazione eseguita con successo ! '
        this.onlyOk = true
        this.onConferma = this.operazioneEseguitaConfirm
        this.confirmText = 'OK'
        this.dialogConferma = true
      },
      operazioneEseguitaConfirm() {
        this.dialogConferma = false
        this.confirmText = 'SI'
        this.onlyOk = false
      }
    }
  }
  </script>

<style lang="scss">
   .nested-badge .v-badge--icon .v-badge__badge {
    padding: 0!important;
  }

  .jsoneditor-outer,  .ace_editor.ace-jsoneditor {
    min-height: 500px;
  }
</style>
