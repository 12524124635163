<template>
  <v-card :loading="loading">
    <v-card-title class="text-h5">
      Download moduli e programmi
      <v-btn icon class="ml-3">
        <v-icon @click="refresh">mdi-refresh</v-icon>
      </v-btn>      
    </v-card-title>
    <v-card-subtitle>Elenco ultime versioni caricate</v-card-subtitle>
    <v-card-subtitle v-if="segnalaAggiornamento" class="font-weight-bold red--text">Attenzione! Alcuni moduli devono essere aggiornati !</v-card-subtitle>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Modulo</th>
            <th class="text-left">Data</th>
            <th class="text-left">Versione</th>
            <th class="text-left">Installato</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(file, i) in files" :key="i">
            <td><a :href="downPath(file)">{{file}}</a></td>
            <td>{{dataModulo(file) | date}}</td>
            <td>{{versioneModulo(file)}}</td>
            <td :class="daAggiornare(file) ? 'red--text' : ''">{{versioneModuloCliente(file)}}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
  import utility from '@/services/utilityService'

  export default {
    props: {
      cliente: Object
    },
    data() {
      return {
        loading: false,
        files: [],
        moduli: [],
        moduliCliente: [],
        aggiornare: {}
      }
    },
    async mounted() {
      await this.refresh()
    },
    computed: {
      segnalaAggiornamento() {
        const keys = Object.keys(this.aggiornare)
        const vv = Object.values(this.aggiornare).filter(x => x === true)
        return vv.length > 0
      }
    },
    methods: {
      async refresh() {
        this.loading = true
        await this.getDirList()
        await this.getModuleVersion()
        if (this.cliente.licenze && this.cliente.licenze[0]) {
          await this.getClientModuleVersion(this.cliente.licenze[0].baseDb)
        }
        this.loading = false
      },
      downPath(file) {
        const service = config.VUE_APP_service_licenze
        return `${service}/utility/repository/file?fn=${file}&k8s=true&ver=v2&key=${this.cliente.licenze && this.cliente.licenze[0].apikey}`
      },
      dataModulo(file) {
        const m = this.moduli.find(x => x.file === file)
        return m ? m.data : ''
      },
      versioneModulo(file) {
        const m = this.moduli.find(x => x.file === file)
        return m ? m.versione : ''
      },
      versioneModuloCliente(file) {
        const m = this.moduli.find(x => x.file === file)
        const c = this.moduliCliente.find(x => x.nome === m.nome)
        return c ? c.versione : '---'
      },
      daAggiornare(file) {
        const vm = this.versioneModulo(file)
        const vc = this.versioneModuloCliente(file)
        const aggiornare = (vc !== '---' && vm !== vc)
        this.$set(this.aggiornare, file, aggiornare)
        return aggiornare
      },
      async getDirList() {
        try {
          this.files = await utility.getk8FileList()
        } catch {}
      },
      async getModuleVersion() {
        try {
          this.moduli = await utility.getModuleVersion()
        } catch {}
      },
      async getClientModuleVersion(basedb) {
        try {
          this.moduliCliente = await utility.getCustomeModuleVersion(basedb)
        } catch {}
      }      
    }
  }
</script>
