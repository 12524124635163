<template>
  <v-dialog v-model="dialogEditor" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-btn icon dark @click="dialogEditor = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Cliente: {{cliente}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialogEditor = false">
            Chiudi
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-list three-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <v-row align="stretch">
                <v-col cols="12" md="6">Database: {{dbName}}</v-col>
                <v-col cols="12" md="6" class="d-flex justify-end">
                  <v-btn color="success" @click="onSalvaJson">Salva</v-btn>
                  <v-btn color="error" class="ml-3 mr-2" @click="onAnnulla">Annulla</v-btn>
                  </v-col>
              </v-row>
            </v-list-item-title>
            <v-container fluid>
              <v-row align="stretch">
                <v-col cols="12" md="6" sm="12">
                  <query :dbName="dbName" :refreshTick="refreshTick" @change="onChangeQuery"/>
                </v-col>          
                <v-col cols="12" md="6" sm="12">                  
                  <v-json-editor v-model="jsonWeb" :options="optionsWeb" :plus="true" @error="onErrorWeb" style="height: 100%; min-height: 600px;"/>
                </v-col>
              </v-row>
            </v-container>               
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <dialog-conferma
      :show="dialogConferma"
      :title="titoloConferma"
      :message="messaggioConferma"
      :onlyOk="onlyOk"
      :confirmText="confirmText"
      @dialog-confirm="onConferma"
      @dialog-cancel="dialogConferma=false; onlyOk=false"
    />    
  </v-dialog>
</template>

<script>
  import configurazioni from '@/services/configurazioniRemoteService'
  import VJsonEditor from 'v-jsoneditor'
  import DialogConferma from '@/components/dialogConferma'
  import Query from './query'
  export default {
    props: {
      dbName: String,
      cliente: String,
      showEditor: { type: Boolean, default: false }
    },
    components: {
      VJsonEditor,
      DialogConferma,
      Query
    },
    data (vm) {
      return {
        dialogEditor: false,
        jsonWeb: '',
        jsonMode: 'view',
        optionsWeb: {
          mode: this.jsonMode,
          modes: ['view', 'form', 'code'],
          search: false,
          enableSort: false,
          enableTransform: false
        },
        dialogConferma: false,
        titoloConferma: '',
        messaggioConferma: '',
        onlyOk: false,
        onConferma: vm.salvaJson,
        confirmText: 'SI',
        refreshTick: 0,
        query: '',
        fields: [],
        limit: 10 
      }
    },
    watch: {
      async showEditor(value) {
        if (value && !this.dbName.endsWith('configurazioni')) {
          this.dialogEditor = value
          await this.refresh()
        }
      }
    },
    async mounted() {
      this.jsonWeb = this.jsonContent || ''
      this.dialogEditor = this.showEditor
      await this.refresh()
    },
    methods: {
      changeMode(mode) {
        const jEd = this.$refs.editor
        this.jsonMode = mode
        jEd.editor.setMode(mode)
      },
      operazioneEseguita() {
        this.titoloConferma = 'Operazione eseguita'
        this.messaggioConferma = 'Operazione eseguita con successo !'
        this.onlyOk = true
        this.onConferma = this.operazioneEseguitaConfirm
        this.confirmText = 'OK'
        this.dialogConferma = true
      },
      operazioneEseguitaConfirm() {
        this.dialogConferma = false
        this.onlyOk = false
        this.confirmText = 'SI'
      },      
      onSalvaJson() {
        this.titoloConferma = 'Salvataggio modifiche'
        this.messaggioConferma = `Confermi salvataggio modifiche ?`
        this.onConferma = this.salvaJson
        this.dialogConferma = true
      },
      async salvaJson() {
        const dati = this.jsonWeb
        await configurazioni.modificaConfigurazione(this.dbName, dati)
        this.dialogConferma = false
        this.refreshTick++
        this.operazioneEseguita()
      },
      async onAnnulla() {
        await this.refresh()
      },
      async onChangeQuery(query) {
        this.query = query
        await this.refresh()
      },
      async refresh() {
        if (this.dbName) {
          const dati = await configurazioni.getDatiGenerici(this.dbName, this.query, this.fields, this.limit)
          this.jsonWeb = dati
        }
      },
      onErrorWeb() {

      }
    }
  }
</script>
